export const loadedState = {
    user: {
      id: '',
      accessToken: '',
      refreshToken: '',
      authType: '',
      firstName: '',
      lastName: '',
      email: '',
    },
  };
  