import {environment} from '../environments';
import axios from 'axios';

class AuthService {
  constructor() {
    console.log(environment.apiMain);
    this.http = axios.create({
      baseURL: environment.apiMain,
    });
  }

  login(formData) {
    return this.http.post('/sign-in', formData);
  }

  passwordReset(formData) {
    return this.http.post('/password-reset', formData);
  }

  passwordChange(formData) {
    return this.http.post('/password-change', formData);
  }
}

export default new AuthService();
