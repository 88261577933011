import { STATE_LOGIN, STATE_SIGNUP } from 'components/AuthForm';
import GAListener from 'components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import AuthPage from 'pages/AuthPage';
import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './styles/reduction.scss';

const AlertPage = React.lazy(() => import('pages/AlertPage'));
const AuthModalPage = React.lazy(() => import('pages/AuthModalPage'));
const BadgePage = React.lazy(() => import('pages/BadgePage'));
const ButtonGroupPage = React.lazy(() => import('pages/ButtonGroupPage'));
const ButtonPage = React.lazy(() => import('pages/ButtonPage'));
const CardPage = React.lazy(() => import('pages/CardPage'));
const ChartPage = React.lazy(() => import('pages/ChartPage'));
const DashboardPage = React.lazy(() => import('pages/DashboardPage'));
const DropdownPage = React.lazy(() => import('pages/DropdownPage'));
const FormPage = React.lazy(() => import('pages/FormPage'));
const InputGroupPage = React.lazy(() => import('pages/InputGroupPage'));
const ModalPage = React.lazy(() => import('pages/ModalPage'));
const ProgressPage = React.lazy(() => import('pages/ProgressPage'));
const TablePage = React.lazy(() => import('pages/TablePage'));
const TypographyPage = React.lazy(() => import('pages/TypographyPage'));
const WidgetPage = React.lazy(() => import('pages/WidgetPage'));
const ProductPage = React.lazy(() => import('pages/ProductPage'));
const CustomerPage = React.lazy(() => import('pages/CustomerPage'));
const OrderPage = React.lazy(() => import('pages/OrderPage'));
const SalePage = React.lazy(() => import('pages/SalePage'));
const SalesPage = React.lazy(() => import('pages/SalesPage'));
const LinkPage = React.lazy(() => import('pages/LinkPage'));
const InventoryPage = React.lazy(() => import('pages/InventoryPage'));
const StorePage = React.lazy(() => import('pages/StorePage'));
const StoreInventoryPage = React.lazy(() => import('pages/StoreInventoryPage'));
const ImagePage = React.lazy(() => import('pages/ImagePage'));
const SalesNewPage = React.lazy(() => import('pages/Sales/sales-new.page'));

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

class App extends React.Component {
  render() {
    return (
      <Provider store={store} >
        <BrowserRouter basename={getBasename()}>
          <GAListener>
            <Switch>
              <LayoutRoute
                exact
                path="/login"
                layout={EmptyLayout}
                component={props => (
                  <AuthPage {...props} authState={STATE_LOGIN} />
                )}
              />
              <LayoutRoute
                exact
                path="/signup"
                layout={EmptyLayout}
                component={props => (
                  <AuthPage {...props} authState={STATE_SIGNUP} />
                )}
              />

              <MainLayout breakpoint={this.props.breakpoint}>
                <React.Suspense fallback={<PageSpinner />}>
                  <Route exact path="/" render={() => <DashboardPage />} />
                  <Route exact path="/login-modal" render={() => <AuthModalPage/>} />
                  <Route exact path="/buttons" render={() => <ButtonPage/>} />
                  <Route exact path="/cards" render={() => <ImagePage/>} />
                  <Route exact path="/widgets" render={() => <WidgetPage/>} />
                  <Route exact path="/typography" render={() => <TypographyPage/>} />
                  <Route exact path="/alerts" render={() => <AlertPage/>} />
                  <Route exact path="/tables" render={() => <TablePage/>} />
                  <Route exact path="/badges" render={() => <BadgePage/>} />
                  <Route exact path="/productos" render={() => <ProductPage/>} />
                  <Route exact path="/inventario" render={() => <InventoryPage/>} />
                  <Route exact path="/tiendas" render={() => <StorePage/>} />
                  <Route exact path="/tiendas/inventario/:id" render={(props) => <StoreInventoryPage {...props}/>} />
                  <Route exact path="/clientes" render={() => <CustomerPage/>} />
                  <Route exact path="/ventas" render={() => <SalePage/>} />
                  <Route exact path="/pedidos" render={() => <SalesPage/>} />
                  <Route exact path="/links" render={() => <LinkPage/>} />
                  <Route exact path="/ventas/new" render={() => <SalesNewPage />} />
                  <Route
                    exact
                    path="/button-groups"
                    render={() => <ButtonGroupPage/>}
                  />
                  <Route exact path="/dropdowns" render={() => <DropdownPage/>} />
                  <Route exact path="/progress" render={() => <ProgressPage/>} />
                  <Route exact path="/modals" render={() => <ModalPage/>} />
                  <Route exact path="/forms" render={() => <FormPage/>} />
                  <Route exact path="/input-groups" render={() => <InputGroupPage/>} />
                  <Route exact path="/charts" render={() => <ChartPage/>} />
                </React.Suspense>
              </MainLayout>
              <Redirect to="/login" />
            </Switch>
          </GAListener>
        </BrowserRouter>
      </Provider>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
