import React from 'react'
import {environment} from '../environments';
import axios from 'axios';

class AppService {
  constructor() {
    this.http = axios.create({
      baseURL: environment.apiMain,
    });
  }

  setAccessToken(accessToken) {
    if(accessToken) {
      this.http.defaults.headers.common['Authorization'] =
      'Bearer ' + accessToken;
    } else {
      return window.location.href = '/login'
    }
  }

  getOptions() {
    return this.http.defaults.headers;
  }
}

export default AppService;
