import logo200Image from 'assets/img/logo/logo_200.png';
import PropTypes from 'prop-types';
import AuthService from '../services/AuthService';
import UserService from '../services/UserService';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import AppHelper from '../helpers/AppHelper';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { addUser} from '../redux/actions/user'
import has from 'lodash/has';

class AuthForm extends React.Component {

  constructor() {
    super();
    this.state = {
      email: '',
      password: ''
    }
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  get isLogin() {
    return this.props.authState === STATE_LOGIN;
  }

  get isSignup() {
    return this.props.authState === STATE_SIGNUP;
  }

  changeAuthState = authState => event => {
    event.preventDefault();
    console.log('aaaaaaaaa')
    this.props.onChangeAuthState(authState);
  };

  handleSubmit = event => {
    event.preventDefault();
    //console.log(this.state)

    (async () => {
      try {
        const response = await AuthService.login(this.state);
        const accessToken = response.data.access_token;

        UserService.setAccessToken(accessToken);
        const getUser = await UserService.me();
        if (!getUser || !has(getUser, 'data')) {
          throw {message: 'No se puede actualizar la informacion del usuario'}
        }

        const user = AppHelper.toCamelCase(getUser.data);
        let payload = {
          ...user,
          accessToken
        };
        this.props.dispatch(addUser(payload))
        this.props.onChangeAuthState(STATE_LOGIN);

      } catch(error) {
          console.log(error);
      }
    })();
    
  };

  

  renderButtonText() {
    const { buttonText } = this.props;

    if (!buttonText && this.isLogin) {
      return 'Login';
    }

    if (!buttonText && this.isSignup) {
      return 'Signup';
    }

    return buttonText;
  }

  render() {
    const {
      showLogo,
      usernameLabel,
      usernameInputProps,
      passwordLabel,
      passwordInputProps,
      confirmPasswordLabel,
      confirmPasswordInputProps,
      children,
      onLogoClick,
    } = this.props;

    return (
      <Form onSubmit={this.handleSubmit}>
        {showLogo && (
          <div className="text-center pb-4">
            <img
              src={logo200Image}
              className="rounded"
              style={{ width: 60, height: 60, cursor: 'pointer' }}
              alt="logo"
              onClick={onLogoClick}
            />
          </div>
        )}
        <FormGroup>
          <Label for={usernameLabel}>{usernameLabel}</Label>
          <Input  type="text"
                  name="email"
                  onChange={this.handleChange}
                  value={this.state.email} />
        </FormGroup>
        <FormGroup>
          <Label for={passwordLabel}>{passwordLabel}</Label>
          <Input  type="password"
                  name="password"
                  onChange={this.handleChange}
                  value={this.state.name} />
        </FormGroup>
        {this.isSignup && (
          <FormGroup>
            <Label for={confirmPasswordLabel}>{confirmPasswordLabel}</Label>
            <Input {...confirmPasswordInputProps}  />
          </FormGroup>
        )}
        <hr />
        <Button
          size="lg"
          className="bg-gradient-theme-left border-0"
          block
          onClick={this.handleSubmit}>
          {this.renderButtonText()}
        </Button>
        {children}
      </Form>
    );
  }
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

AuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Email',
  usernameInputProps: {
    type: 'email',
    placeholder: 'your@email.com',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
  onLogoClick: () => {},
};

export default connect(null)(AuthForm);
